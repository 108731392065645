import { useBreakpoint } from "gatsby-plugin-breakpoints";
import React, { useEffect, useState } from "react";
import IndexJsonLd from "../components/IndexjsonLd";
import LoadingCircular from "../components/loadingCircular";
import { default as Seo } from "../layouts/head";
import Layout from "../layouts/pc";
import LayoutSP from "../layouts/sp";
import { default as AboutUsPC } from "../templates/pc/about-us";
import AboutUsSP from "../templates/sp/about-us";

const AboutUs = () => {
  // if (process.env.GATSBY_COUNTDOWN) {
  //   navigate("/countdown");
  // }
  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const title = "ロートリについて";
  useEffect(() => {
    setTimeout(() => setLoading(false), 50);
  }, []);
  return (
    <>
      <Seo title={title} />
      <IndexJsonLd title={title} />
      {loading ? (
        <LoadingCircular />
      ) : breakpoints.sm ? (
        <LayoutSP>
          <AboutUsSP />
        </LayoutSP>
      ) : (
        <Layout>
          <AboutUsPC />
        </Layout>
      )}
    </>
  );
};

export default AboutUs;
