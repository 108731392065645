import nakaoIcon from "../images/aboutUs/nakao-icon.png";
import nishizawaIcon from "../images/aboutUs/nishizawa-icon.png";
import shimosatoIcon from "../images/aboutUs/shimosato-icon.png";
import yoshidaIcon from "../images/aboutUs/yoshida-icon.png";

export const memberInfo = [
  {
    catchCopy: "コロッケ探しの旅をしたい",
    catchcopycolor: "#CAAC60",
    memberName: "中尾 勇斗",
    birthday: "1996年6月生まれ",
    iconImg: nakaoIcon,
    introduction:
      "Lagopusをひっぱる大食漢。高校卒業後、大手インフラ会社に就職。安定した生活を送る中で、大きな挑戦をしてみたいという思いからLagopusを結成。「人生の最後、後悔をしないために」をモットーに日々邁進中。",
  },
  {
    catchCopy: "思い立ったが吉日",
    catchcopycolor: "#33CC95",
    memberName: "吉田 幸太郎",
    birthday: "1996年10月生まれ",
    iconImg: yoshidaIcon,
    introduction:
      "Lagopusの頼れる大黒柱。広告代理店、スタートアップ企業の立ち上げを経験した後に、Lagopusを結成。冷静な視点でチームを陰ながら支えている。Lagopus唯一のエンジニア。",
  },
  {
    catchCopy: "Lagopusのお調子者",
    catchcopycolor: "#48A0D9",
    memberName: "西澤 雄太",
    birthday: "1997年3月生まれ",
    iconImg: nishizawaIcon,
    introduction:
      "Lagopusのムードメーカー。大学卒業後、大手Webマーケティング会社に就職。地方創生をしたく、地元の友人と起業する夢を叶えるためLagopusを結成。Lagopus結成の発起人。",
  },
  {
    catchCopy: "いつもポジティブ",
    catchcopycolor: "#EA7E5C",
    memberName: "下里 優真",
    birthday: "1996年4月生まれ",
    iconImg: shimosatoIcon,
    introduction:
      "Lagopusのご意見番。専門学校卒業後は、ゲームメーカーに就職。ゲームで地元を盛り上げたいという夢を掲げてLagopusを結成。現在は、ご意見番としてLagopusを見守っている。",
  },
];
