import { Typography } from "@mui/material";
import React from "react";

import styled, { css } from "styled-components";
import { default as BaseContact } from "../../components/contact";
import { default as BaseMember } from "../../components/member";
import { memberInfo } from "../../helpers/member";
import { urlInfo } from "../../helpers/url";
import lagopusLogo from "../../images/aboutUs/lagopus-feture.jpg";
import topBackground from "../../images/background.png";
import logo from "../../images/logo.svg";
import instagram from "../../images/sns/instagram-icon.png";
import tiktok from "../../images/sns/tiktok-icon.png";
import twitter from "../../images/sns/twitter-icon.png";
import youtube from "../../images/sns/youtube-icon.png";

const AboutUs = () => {
  return (
    <>
      <MainDiv>
        <FirstDiv>
          <Title>ロートリとは</Title>
          <Logo src={logo} alt="alt" />
          <DescriptionDiv>
            <Description>
              ロートリでは、地方の企業が行っている面白いビジネスを、その土地の情報とあわせてわかりやすく紹介しています。
            </Description>
            <StyledRotoriDescriptionDiv>
              <Description>
                ビジネスモデルが思いつかない！という時に、当サイトに足を運んでいただければと思います。
              </Description>
            </StyledRotoriDescriptionDiv>
            <StyledRotoriDescriptionDiv>
              <Description>
                また、ロートリをきっかけに、地方の面白いビジネスを皆さまに知っていただければ幸いです。
              </Description>
            </StyledRotoriDescriptionDiv>
          </DescriptionDiv>
          <NameOriginDiv>
            <NameOriginTitle>ロートリの由来</NameOriginTitle>
            <StyledRotoriOriginDescriptionDiv>
              <Description>
                地方創生のきっかけという意味の「ローカルトリガー」と、起業という長い道のりを旅するという意味の「ロードトリップ」の二つの意味から名づけられました。
              </Description>
            </StyledRotoriOriginDescriptionDiv>
          </NameOriginDiv>
        </FirstDiv>
        <SecondDiv>
          <Title>Lagopus</Title>
          <LagopusLogo src={lagopusLogo} alt="lagopusLogo" />
          <StyledLagopusDescriptionDiv>
            <Description>
              Lagopusは、地元の幼馴染が集まって結成した
              ふるさと総合支援チームです。
            </Description>
          </StyledLagopusDescriptionDiv>
          <SnsDiv>
            <a target="_blank" href={urlInfo.twitter} rel="noreferrer">
              <SnsLogo src={twitter} alt="twitterLogo" />
            </a>
            <a target="_blank" href={urlInfo.instagram} rel="noreferrer">
              <SnsLogo src={instagram} alt="instagramLogo" />
            </a>
            <a target="_blank" href={urlInfo.tiktok} rel="noreferrer">
              <SnsLogo src={tiktok} alt="tiktokLogo" />
            </a>
            <a target="_blank" href={urlInfo.youtube} rel="noreferrer">
              <SnsLogo src={youtube} alt="youtubeLogo" />
            </a>
          </SnsDiv>
        </SecondDiv>
        <MemberIntroductionDiv>
          <Title>メンバー紹介</Title>
          <MemberDiv>
            {memberInfo.map((props) => {
              return <Member key={props.memberName} {...props} />;
            })}
          </MemberDiv>
        </MemberIntroductionDiv>

        <ContactDiv>
          <Contact />
        </ContactDiv>
      </MainDiv>
    </>
  );
};

const MainDiv = styled.div`
  background-image: url(${topBackground});
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  display: block;
  width: 100vw;
`;

const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
`;

const SecondDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

const MemberIntroductionDiv = styled.div`
  margin-top: 70px;
`;

const MemberDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 70px;
  justify-content: center;
  width: 50%;
  margin: 0 auto;
  margin-top: 66px;
`;

const Member = styled(BaseMember)``;

const SnsDiv = styled.div`
  display: flex;
  gap: 35px;
  margin-top: 50px;
`;
const SnsLogo = styled.img`
  width: 100px;
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.xl4};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    text-align: center;
  `}
`;

const DescriptionDiv = styled.div`
  margin: 0 auto;
  margin-top: 50px;
  width: 600px;
`;
const StyledRotoriDescriptionDiv = styled(DescriptionDiv)`
  margin-top: 10px;
`;
const StyledRotoriOriginDescriptionDiv = styled(DescriptionDiv)`
  margin-top: 10px;
  width: 571px;
`;
const StyledLagopusDescriptionDiv = styled(DescriptionDiv)`
  margin-top: 50px;
  width: 373px;
`;

const Description = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    line-height: 24px;
  `}
`;

const Logo = styled.img`
  width: 400px;
  height: 320px;
  border-radius: 10px;
  margin-top: 50px;
`;

const LagopusLogo = styled.img`
  width: 400px;
  border-radius: 10px;
  margin-top: 50px;
`;

const NameOriginDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white.primary};
    margin-top: 50px;
    padding: 30px 40px;
    border-radius: 10px;
  `}
`;

const NameOriginTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.base};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    color: ${theme.colors.black.primary};
    text-align: center;
  `}
`;

const ContactDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  padding-bottom: 70px;
`;

const Contact = styled(BaseContact)``;

export default AboutUs;
