import { Typography } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components";

const Member = ({ className, issp, ...props }) => {
  return (
    <TopDiv className={className} issp={issp}>
      <IconImg src={props.iconImg} issp={issp} />
      <CatchCopy catchcopycolor={props.catchcopycolor} issp={issp}>
        {props.catchCopy}
      </CatchCopy>
      <MemberName issp={issp}>{props.memberName}</MemberName>
      <BirthDayDescription issp={issp}>{props.birthday}</BirthDayDescription>
      <Description issp={issp}>{props.introduction}</Description>
    </TopDiv>
  );
};

const TopDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white.primary};
    width: ${(props) =>
      props.issp ? `calc(337px - 40px)` : `calc(404px - 50px)`};
    padding: ${(props) => (props.issp ? `19px 20px` : `25px`)};
    position: relative;
    border-radius: 10px;
  `}
`;

const IconImg = styled.img`
  position: absolute;
  top: ${(props) => (props.issp ? `-5%` : `-15%`)};
  right: ${(props) => (props.issp ? `3%` : `3%`)};
  width: ${(props) => (props.issp ? `100px` : `120px`)};
`;

const CatchCopy = styled(Typography)`
  ${({ theme }) => css`
      color: ${(props) =>
        props.catchcopycolor ? `${props.catchcopycolor}` : `#333`}
    font-size: ${(props) =>
      props.issp ? `${theme.fonts.size.sm}` : `${theme.fonts.size.base}`};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${(props) =>
      props.issp
        ? `${theme.fonts.spacing.primarySP}`
        : `${theme.fonts.spacing.primary}`};
    color: ${(props) => `${props.catchcopycolor}`};
    margin-bottom: 5px;
  `};
`;

const MemberName = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${(props) =>
      props.issp ? `${theme.fonts.size.sm}` : `${theme.fonts.size.base}`};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${(props) =>
      props.issp
        ? `${theme.fonts.spacing.primarySP}`
        : `${theme.fonts.spacing.primary}`};
    color: ${theme.colors.black.primary};
    margin-bottom: 5px;
  `};
`;

const Description = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${(props) =>
      props.issp ? `${theme.fonts.size.sm}` : `${theme.fonts.size.base}`};
    letter-spacing: ${(props) =>
      props.issp
        ? `${theme.fonts.spacing.primarySP}`
        : `${theme.fonts.spacing.primary}`};
    color: ${theme.colors.black.primary};
    line-height: 24px;
  `};
`;

const BirthDayDescription = styled(Description)`
  margin-bottom: 10px;
`;

export default Member;
