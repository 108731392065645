import { ArrowForwardIos as BaseArrowForwardIos } from "@mui/icons-material";
import { Button as BaseButton, Typography } from "@mui/material";
import { Link } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";

const BaseContact = ({ className }) => {
  return (
    <TopDiv className={className}>
      <ContentDiv>
        <TextDiv>
          <HeadlineText>あなたも地方の顔になりませんか。</HeadlineText>
          <HeadlineText>
            掲載のご依頼については、下記お問い合わせフォームよりご連絡ください。
          </HeadlineText>
        </TextDiv>
        <ButtonDiv>
          <Button
            component={Link}
            to="/contact/"
            id="page-contact-button"
            gtm-page-contact={`page-contact`}
          >
            お問い合わせ <ArrowForwardIos />
          </Button>
        </ButtonDiv>
      </ContentDiv>
    </TopDiv>
  );
};

const TopDiv = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.blue.primary};
    border-radius: 10px;
  `}
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentDiv = styled.div`
  padding: 40px;
`;

const HeadlineText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.colors.white.primary};
    font-weight: ${theme.fonts.weight.bold};
    font-size: ${theme.fonts.size.xl2};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 32px;
    display: inline-block;
  `}
`;

const ArrowForwardIos = styled(BaseArrowForwardIos)`
  ${({ theme }) => css`
    color: ${theme.colors.blue.primary};
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    line-height: 32px;
  `}
`;

const ButtonDiv = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const Button = styled(BaseButton)`
  ${({ theme }) => css`
    padding: 23px 130px;
    color: ${theme.colors.blue.primary};
    font-size: ${theme.fonts.size.xl2};
    font-weight: ${theme.fonts.weight.bold};
    letter-spacing: ${theme.fonts.spacing.primary};
    background-color: ${theme.colors.white.primary};
    border-radius: 10px;

    &:hover {
      color: ${theme.colors.blue.primary};
      background-color: ${theme.colors.white.primary};
    }
  `}
`;

const Contact = styled(BaseContact)``;

export default Contact;
